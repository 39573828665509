import React from 'react';
import { Helmet } from "react-helmet"

import * as s from './index.module.scss';

import Nav from '../components/Nav';
import Hero from '../components/Hero';
import Button from '../components/Button';
import Footer from '../components/Footer';

import heroImage from '../images/hero-websites-design.png'
import heroImageMobile from '../images/laptop-hero-website.png'
import checkSvg from '../images/check.svg';
import Head from '../components/Head';


export default function HomePage() {
    return (
        <div>
            <Head>
                <title>Créations et design de site webs - Websites & Design</title>
            </Head>
            <Nav />
            <Hero paddedHorizontal={false}>
                <div id={s.heroInfos}>
                    <h1 id={s.heroTitle}>Présentez et gérez votre activité</h1>
                    <ul id={s.heroChecklist}>
                        <li>
                            <img className={s.check} src={checkSvg} />
                            Entreprise du batiment
                        </li>
                        <li>
                            <img className={s.check} src={checkSvg} />
                            Boutique en ligne
                        </li>
                        <li>
                            <img className={s.check} src={checkSvg} />
                            Restaurateurs
                        </li>
                        <li>
                            <img className={s.check} src={checkSvg} />
                            Agence immobilière
                        </li>
                        <li>
                            <img className={s.check} src={checkSvg} />
                            Hotel - Auberge - Lodge
                        </li>
                        <li>
                            <img className={s.check} src={checkSvg} />
                            Profession indépendante
                        </li>
                        <li>
                            <img className={s.check} src={checkSvg} />
                            Blog payant et gratuit
                        </li>
                        <li>
                            <img className={s.check} src={checkSvg} />
                            Établissements éducatifs
                        </li>
                    </ul>
                </div>
                <img className={s.heroImage} src={heroImage} />
                <img className={s.heroImageMobile} src={heroImageMobile} />
            </Hero>

            <div id={s.content} className="container">

                <div id={s.priceBlock} className={s.block}>
                    <h3 id={s.priceBlockTitle}>Achetez votre site web à partir de 150 €</h3>

                    <p className={s.descBig}>
                        Base site web à 150 € (1 à 2 pages), 250 € (2 à 7 pages), boutique en ligne à 250 €.
                        <br/>Des fonctionnalités sont disponibles, contenu avec abonnement, paiement en ligne, réservations, et plus encore.
                    </p>

                    <Button to="/tarifs-et-devis">
                        Voir l'ensemble des tarifs
                    </Button> 
                </div>


                <div id={s.whyUsBlock} className={s.block}>

                    <div className='row'>
                        <div className='col-lg-8'>
                            <h3>Un site web ou une boutique en ligne de qualité, qui vous appartient</h3>
                            
                            <p className={s.descBig}>
                                Nous utilisons le logiciel WordPress. WordPress est un logiciel utilisé par plus de 40% du web
                            </p>
                            <p className={s.desc}>
                                Nous sélectionnons un thème graphique selon votre activité et vos demandes : photos et vidéos mises en valeur,  couleurs,  pages,  design simple - complet - artistique ou professionnel,  paragraphes de texte. Et nous personnalisons le thème.
                                <br/>Nous récupérons vos textes et images à afficher, ou nous rédigeons ces textes et photographions vos objets ou lieux.
                                <br/>Contactez-nous pour obtenir des recommandations de modules et fonctionnalités pour votre activité : réservations en ligne,  administration de listes d'objets,  plusieurs languages,  blog gratuit et payant,  galerie photo, newsletter et formulaire de contact.
                            </p>
                        </div>

                        <div className={`col-lg-3 offset-lg-1 ${s.centeredVertical}`}>
                            <Button to="/notre-entreprise">
                                Plus sur notre entreprise et la fabrication de votre site web
                            </Button>
                        </div>
                    </div>
                </div>


                <div id={s.demosBlock} className={s.block}>
                    <h3>Voyez de vos propres yeux</h3>

                    <p>Nos commandes passées et nos démos sont en ligne</p>

                    <Button to="/realisations-et-demos">
                        Voir les démos de réalisation
                    </Button>
                </div>
            </div>

            <Footer />
        </div>
    )
}