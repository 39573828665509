// extracted by mini-css-extract-plugin
export var heroInfos = "index-module--heroInfos--2bDpg";
export var heroTitle = "index-module--heroTitle--S883r";
export var heroImage = "index-module--heroImage--3LR1G";
export var heroImageMobile = "index-module--heroImageMobile--2xg50";
export var heroChecklist = "index-module--heroChecklist--3N_bN";
export var check = "index-module--check--3VtqF";
export var block = "index-module--block--2U6oI";
export var descBig = "index-module--descBig--1tE-H";
export var desc = "index-module--desc--3ovD0";
export var centeredVertical = "index-module--centeredVertical--1DYI4";
export var priceBlock = "index-module--priceBlock--3LyJ3";
export var priceBlockTitle = "index-module--priceBlockTitle--389xT";
export var priceBlockContent = "index-module--priceBlockContent--14DmH";
export var demosBlock = "index-module--demosBlock--3tpug";