import React from 'react';
import { Link } from 'gatsby';

import * as s from './Button.module.scss';

export default function Button({
    Component = Link,
    children,
    ...others
}) {
    return (
        <Component className={s.button} {...others}>
            {children}
        </Component>
    )
}